import { useMemo, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Selector as SelectorIcon } from '../icons/selector';
import { Cog } from '../icons/cog';
import { Logo } from '../components/logo';
import { Scrollbar } from '../components/scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { OrganizationPopover } from './organization-popover';
import { NavLink } from 'react-router-dom';
import { ClipboardList } from '../icons/clipboard-list';
import { LightBulb } from '../icons/light-bulb';
import { useAuth } from '../hooks/use-auth';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import Alert from '@mui/material/Alert';
import { Reports } from '../icons/reports';
import { ScmOrgIdContext } from '../contexts/scm_user_org_id_context.jsx';
import { ChartLine } from '../icons/chart-line';
import { ChartComparison } from '../icons/chart-comparison.jsx';

const getSections = () => [
    {
        title: '',
        items: [
            {
                title: 'Created Reviews',
                path: '/dashboard/created-reviews',
                icon: <LightBulb fontSize="small" />,
            },
            {
                title: 'Needs Review',
                path: '/dashboard/needs-review',
                icon: <ClipboardList fontSize="small" />,
            },
            // {
            //     title: 'Checks',
            //     path: '/dashboard/checks',
            //     icon: <Reports fontSize="small" />,
            // },
            // {
            //     title: 'My Stats',
            //     path: '/dashboard/stats/my',
            //     icon: <ChartLine fontSize="small" />,
            // },
            // {
            //     title: 'Org Stats',
            //     path: '/dashboard/org-stats',
            //     icon: <ChartBarIcon fontSize="small" />,
            // },
            {
                title: 'Comparison Stats',
                path: '/dashboard/stats/comparison',
                icon: <ChartComparison fontSize="small" />,
            },
            {
                title: 'Settings',
                path: '/dashboard/settings',
                icon: <Cog fontSize="small" />,
            },
        ],
    },
];

const ALL_ORG = {
    id: '',
    name: 'All Organizations',
};

export const DashboardSidebar = (props) => {
    const { onClose, open } = props;
    const { user } = useAuth();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        noSsr: true,
    });
    const sections = useMemo(() => getSections(), []);
    const { scmOrgId, setScmOrgId } = useContext(ScmOrgIdContext);
    const organizationsRef = useRef(null);
    const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(false);

    const handleOpenOrganizationsPopover = () => {
        setOpenOrganizationsPopover(true);
    };

    const handleCloseOrganizationsPopover = () => {
        setOpenOrganizationsPopover(false);
    };

    const scmOrgs = useMemo(() => {
        if (!user) {
            return null;
        }
        return user.userOrgs.map((userOrg) => userOrg.scmOrg);
    }, [user]);

    const currentScmOrg = useMemo(() => {
        if (!scmOrgs) {
            return null;
        }
        if (scmOrgId == null) {
            return null;
        }
        if (scmOrgId === '') {
            if (scmOrgs.length > 1) {
                return ALL_ORG;
            } else {
                return scmOrgs[0];
            }
        }

        return scmOrgs.find((scmOrg) => scmOrg.id === scmOrgId);
    }, [scmOrgs, scmOrgId]);

    const handleChangeScmOrg = (scmOrgId) => {
        setScmOrgId(scmOrgId);
        handleCloseOrganizationsPopover();
    };

    const multipleOrgs = scmOrgs && scmOrgs.length > 1;

    const content = (
        <>
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div>
                        <Box sx={{ p: 3 }}>
                            <NavLink to="/" style={{ textDecoration: 'none' }}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <Logo
                                        sx={{
                                            height: 42,
                                            width: 42,
                                        }}
                                    />
                                    <Typography fontWeight="bold" color="white" fontSize="23px">
                                        PullNotify
                                    </Typography>
                                </Box>
                            </NavLink>
                        </Box>
                        <Box sx={{ px: 2 }}>
                            <Box
                                onClick={multipleOrgs ? handleOpenOrganizationsPopover : null}
                                ref={organizationsRef}
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                    cursor: multipleOrgs ? 'pointer' : '',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    px: 2,
                                    py: 1,
                                    borderRadius: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    {currentScmOrg && currentScmOrg.avatarUrl && (
                                        <Avatar
                                            sx={{
                                                height: 30,
                                                width: 30,
                                                mr: 1,
                                            }}
                                            src={currentScmOrg.avatarUrl}
                                        >
                                            <UserCircleIcon fontSize="small" />
                                        </Avatar>
                                    )}
                                    <Typography color="inherit" variant="subtitle1">
                                        {currentScmOrg ? currentScmOrg.name : 'Loading...'}
                                    </Typography>
                                </Box>

                                {multipleOrgs && (
                                    <SelectorIcon
                                        sx={{
                                            color: 'neutral.500',
                                            width: 14,
                                            height: 14,
                                        }}
                                    />
                                )}
                            </Box>
                            {currentScmOrg && ['ready', 'running'].includes(currentScmOrg.dataLoadStatus) && (
                                <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
                                    Data backfill in progress. Stats and PRs may be wrong during this time.
                                </Alert>
                            )}
                        </Box>
                    </div>

                    <Divider
                        sx={{
                            borderColor: '#2D3748',
                            my: 3,
                        }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                        {sections.map((section) => (
                            <DashboardSidebarSection
                                key={section.title}
                                path={'/'}
                                sx={{
                                    mt: 2,
                                    '& + &': {
                                        mt: 2,
                                    },
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                </Box>
            </Scrollbar>
            {scmOrgs && (
                <OrganizationPopover
                    anchorEl={organizationsRef.current}
                    onChange={handleChangeScmOrg}
                    onClose={handleCloseOrganizationsPopover}
                    open={openOrganizationsPopover}
                    scmOrgs={scmOrgs}
                />
            )}
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        borderRightColor: 'divider',
                        borderRightStyle: 'solid',
                        borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
                        color: '#FFFFFF',
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
