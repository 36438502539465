import { createSvgIcon } from '@mui/material/utils';

export const ChartComparison = createSvgIcon(
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="SsidChartIcon"
    >
        <path d="M21 5.47 12 12 7.62 7.62 3 11V8.52L7.83 5l4.38 4.38L21 3v2.47zM21 15h-4.7l-4.17 3.34L6 12.41l-3 2.13V17l2.8-2 6.2 6 5-4h4v-2z"></path>
    </svg>,
    'ChartComparison',
);
