// scroll bar
import 'simplebar/src/simplebar.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

const errorLink = onError(({ operation, forward, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err.extensions.code) {
                // Apollo Server sets code to UNAUTHENTICATED
                // when an AuthenticationError is thrown in a resolver
                case 'UNAUTHENTICATED':
                    window.location = '/api/github/oauth';
            }
        }
    }
});

const httpLink = new HttpLink({
    uri: '/api/graphql',
});
const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

// ----------------------------------------------------------------------

const root = createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </HelmetProvider>,
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
