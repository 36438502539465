import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
    Card,
    Container,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TableContainer,
    Skeleton,
    TableHead,
    Paper,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CircularProgress as Loading } from '@mui/material';
import Page from '../components/Page.jsx';

const STATS_DAYS = [
    {
        value: 1,
        label: '1 Day',
    },
    {
        value: 7,
        label: '7 Days',
    },
    {
        value: 30,
        label: '30 Days',
    },
    {
        value: 90,
        label: '90 Days',
    },
    {
        value: 180,
        label: '180 Days',
    },
    {
        value: 365,
        label: '1 Year',
    },
];

const PRS_QUERY = gql`
    {
        commitStatusStats {
            name
            fail_count
            fail_percent
            success_count
            success_percent
            run_time_seconds_median
            run_time_seconds_p95
        }
    }
`;

export default function CommitStatuses() {
    // const [search, setSearch] = useSearchParams();
    // const [selectedStat, setSelectedStat] = useState(defaultStat);
    const defaultDays = STATS_DAYS[STATS_DAYS.length - 1].value;
    const [duration, setDuration] = useState(defaultDays);
    const { loading, data, refetch } = useQuery(PRS_QUERY);
    const rows = data ? data.commitStatusStats : [];

    // useEffect(() => {
    //     setSearch({
    //         stat: selectedStat.value,
    //         duration: duration.value,
    //     });
    // }, [duration, selectedStat]);

    const table = () => {
        const columns = [
            { field: 'name', headerName: 'Name' },
            {
                field: 'success_percent',
                type: 'number',
                headerName: 'Success Percent',
                description: 'Percentage of time check returned a success status. Ignores skips.',
            },
            {
                field: 'fail_percent',
                type: 'number',
                headerName: 'Fail Percent',
                description: 'Percentage of time check returned a fail or error status. Ignores skips.',
            },
            { field: 'success_count', type: 'number', headerName: 'Success Count' },
            { field: 'fail_count', type: 'number', headerName: 'Fail Count' },
            { field: 'run_time_seconds_median', type: 'number', headerName: 'Median Run Time' },
            { field: 'run_time_seconds_p95', type: 'number', headerName: 'P95 Run Time' },
        ];

        return (
            <DataGrid
                autoHeight
                getRowId={(row) => row.name}
                components={{ Toolbar: GridToolbar }}
                csvOptions={{
                    fileName: 'build-stats.csv',
                }}
                rows={rows}
                columns={columns}
            />
        );
    };

    const table2 = () => {
        let tableRows;
        let showHeader = true;
        if (!data) {
            showHeader = false;
            tableRows = Array(3)
                .fill(0)
                .map((_, index) => (
                    <TableRow key={`loading_${index}`}>
                        <TableCell>
                            <Skeleton variant="rectangular" height={75} />
                        </TableCell>
                    </TableRow>
                ));
        } else if (rows.length === 0) {
            showHeader = false;
            tableRows = [
                <TableRow key="none">
                    <TableCell sx={{ py: 4, fontWeight: 'bold', fontSize: '1.2rem' }} style={{ textAlign: 'center' }}>
                        No status checks
                    </TableCell>
                </TableRow>,
            ];
        } else {
            tableRows = rows.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell>{row.success_count}</TableCell>
                    <TableCell>{row.fail_count}</TableCell>
                    <TableCell>{row.run_time_seconds_median}</TableCell>
                    <TableCell>{row.run_time_seconds_p95}</TableCell>
                </TableRow>
            ));
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {showHeader ? (
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Success Count</TableCell>
                                <TableCell>Fail Count</TableCell>
                                <TableCell>Median Seconds</TableCell>
                                <TableCell>P95 Seconds</TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell sx={{ height: 42 }}></TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    // const handleStatChange = ({ target }) => {
    //     const newStat = STATS_OPTIONS.find((stat) => stat.value === target.value);
    //
    //     setSelectedStat(newStat);
    //     refetch({ name: newStat.value, duration: duration.value });
    // };
    //
    const handleDurationChange = ({ target }) => {
        const duration = target.value;
        setDuration(duration);
        // refetch({ name: selectedStat.value, duration });
    };

    const menuItems = STATS_DAYS.map((stat) => (
        <MenuItem key={stat.value} value={stat.value}>
            {stat.label}
        </MenuItem>
    ));

    return (
        <Page title="Status Checks">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Status Checks
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <FormControl sx={{ m: 1, minWidth: 140 }}>
                            <InputLabel id="days-filter-label">Repo Filter</InputLabel>
                            <Select labelId="days-filter-label" id="days-filter" value={duration} label="Days">
                                {menuItems}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 140 }}>
                            <InputLabel id="days-filter-label">Days Filter</InputLabel>
                            <Select
                                labelId="days-filter-label"
                                id="days-filter"
                                value={duration}
                                label="Days"
                                onChange={handleDurationChange}
                            >
                                {menuItems}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <Card>{loading ? <Loading /> : table2()}</Card>
            </Container>
        </Page>
    );
}
