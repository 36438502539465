import { Drawer } from '@mui/material';

export const DetailDrawer = ({ containerRef, children, open, onClose, ...other }) => {
    return (
        <Drawer
            PaperProps={{
                sx: {
                    zIndex: 1400,
                },
            }}
            ModalProps={{
                sx: {
                    zIndex: 1400,
                },
            }}
            anchor="right"
            open={open}
            variant="temporary"
            onClose={onClose}
        >
            {children}
        </Drawer>
    );
};
