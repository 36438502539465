import { createSvgIcon } from '@mui/material/utils';

export const ChartLine = createSvgIcon(
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ShowChartIcon"
    >
        <path d="m3.5 18.49 6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path>
    </svg>,
    'ChartLine',
);
