// material
import { Stack, Container } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';
import Page from '../components/Page';
import { StatCard } from '../components/StatCard';
import { useAuth } from '../hooks/use-auth.js';
// import {useScmOrgs} from "../hooks/use_scm_orgs.js";
import { useEffect } from 'react';

const MY_STAT_QUERY = gql`
    query MyStats($userInfoIds: [String!]!) {
        prsMerged: userStatsOverTime(name: pullRequestCount, grouping: EVERY_4_WEEKS, userInfoIds: $userInfoIds) {
            month
            value
        }
        reviewCount: userStatsOverTime(name: reviewCount, grouping: EVERY_4_WEEKS, userInfoIds: $userInfoIds) {
            month
            value
        }
        reviewRoundTime: userStatsOverTime(name: reviewRoundTime, grouping: EVERY_4_WEEKS, userInfoIds: $userInfoIds) {
            month
            value
        }
        pullRequestTime: userStatsOverTime(name: pullRequestTime, grouping: EVERY_4_WEEKS, userInfoIds: $userInfoIds) {
            month
            value
        }
        authorApproval: userStatsOverTime(name: authorApproval, grouping: EVERY_4_WEEKS, userInfoIds: $userInfoIds) {
            month
            value
        }
        totalLinesChanged: userStatsOverTime(
            name: totalLinesChanged
            grouping: EVERY_4_WEEKS
            userInfoIds: $userInfoIds
        ) {
            month
            value
        }
    }
`;

export default function MyStats() {
    const { user } = useAuth();
    const [queryStat, { data, loading }] = useLazyQuery(MY_STAT_QUERY);
    useEffect(() => {
        if (!user) {
            return;
        }
        queryStat({ variables: { userInfoIds: [user.id] } });
    }, [user]);

    return (
        <Page title="Open Reviews">
            {data && (
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <StatCard title={'PRs Merged'} data={data.prsMerged.map((stat) => stat.value)} />
                        <StatCard title={'Review Count'} data={data.reviewCount.map((stat) => stat.value)} />
                        <StatCard
                            title={'Review Round Time'}
                            data={data.reviewRoundTime.map((stat) => stat.value)}
                            units={' hour(s)'}
                            lowerIsBetter={true}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <StatCard
                            title={'Pull Request Time'}
                            data={data.pullRequestTime.map((stat) => stat.value)}
                            units={' hour(s)'}
                            lowerIsBetter={true}
                        />
                        <StatCard
                            title={'Approval as Author'}
                            data={data.authorApproval.map((stat) => stat.value)}
                            lowerIsBetter={null}
                            units={'%'}
                        />
                        <StatCard
                            title={'Total Lines Changed'}
                            data={data.totalLinesChanged.map((stat) => stat.value)}
                        />
                    </Stack>
                </Container>
            )}
        </Page>
    );
}
