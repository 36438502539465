import { Stack, Button } from '@mui/material';
import { ArrowRight } from '../../icons/arrow-right.jsx';
import { DisconnectButton } from './DisconnectButton';

export const SlackConnections = ({ slackConnections, onDisconnect }) => {
    return (
        <>
            {slackConnections.map((connection) => (
                <Stack key={connection.id} spacing={2} direction="row" sx={{ mb: 4, alignItems: 'center' }}>
                    <span>{connection.orgName}</span>
                    <ArrowRight />
                    {connection.slackTeam && <span>{connection.slackTeam}</span>}
                    {connection.slackTeam && <DisconnectButton onDisconnect={() => onDisconnect(connection.id)} />}
                    {!connection.slackTeam && (
                        <Button onClick={() => (window.location.href = '/api/slack/oauth')} variant="contained">
                            Connect to Slack
                        </Button>
                    )}
                </Stack>
            ))}
        </>
    );
};
