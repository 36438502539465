import {
    Box,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { format, formatDistanceToNowStrict } from 'date-fns';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { SeverityPill } from '../../components/severity-pill';
import { useTheme } from '@mui/material/styles';

export const reviewRoundStatusToPill = (reviewRound) => {
    if (!reviewRound) {
        return <SeverityPill color="info">Active</SeverityPill>;
    }

    switch (reviewRound.status) {
        case 'changes_requested':
            return <SeverityPill color="error">Changes Requested</SeverityPill>;
        case 'approved':
            return <SeverityPill color="success">Approved</SeverityPill>;
        case 'comment':
            return <SeverityPill color="warning">Comment</SeverityPill>;
        case 'active':
            return <SeverityPill color="info">Active</SeverityPill>;
    }
};

const getDueOn = (review) => {
    if (review.dueOn) {
        return new Date(review.dueOn);
    }
    if (review.status === 'completed') {
        return 'Completed';
    }

    return 'Waiting for rerequest';
};

export const ReviewsDetailTable = ({ rows }) => {
    const theme = useTheme();

    const tableRows = rows.map((row) => {
        const dueOn = getDueOn(row);

        return (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    <Link
                        target="_blank"
                        href={row.lastReviewRound && row.lastReviewRound.htmlUrl}
                        rel="noreferrer"
                        underline="none"
                    >
                        <Typography color={theme.palette.common.white} fontWeight="bold">
                            {row.reviewer.username}
                        </Typography>
                    </Link>
                </TableCell>
                <TableCell>{reviewRoundStatusToPill(row.lastReviewRound)}</TableCell>
                <TableCell>
                    <Tooltip
                        disabled={typeof dueOn === 'string'}
                        title={
                            typeof dueOn === 'string'
                                ? ''
                                : formatDistanceToNowStrict(dueOn, { unit: 'hour', addSuffix: true })
                        }
                    >
                        <Typography>{typeof dueOn === 'string' ? dueOn : format(dueOn, 'MMM, dd HH:mm')}</Typography>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Reviewer</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Due</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
        </TableContainer>
    );
};
