import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, FormControl, InputLabel, Link, Select, MenuItem, Stack } from '@mui/material';
import Page from '../components/Page';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { ArrowRight } from '../icons/arrow-right.jsx';
// components

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 600,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const SCM_ORGS_QUERY = gql`
    {
        scmUserOrgs(admin: true) {
            id
            scmOrg {
                id
                name
            }
        }
        tempSlackUserData {
            slackDisplayName
            slackTeamName
        }
    }
`;

const SCM_ORG_CONFIRM_SLACK = gql`
    mutation ConfirmSlackConnection($scmUserOrgId: String!) {
        scmUserOrgConfirmSlack(scmUserOrgId: $scmUserOrgId)
    }
`;

export default function SlackConfirm() {
    const navigate = useNavigate();
    const { data } = useQuery(SCM_ORGS_QUERY);
    const [confirmSlack, { data: savedData }] = useMutation(SCM_ORG_CONFIRM_SLACK);
    const [selectedScmUserOrgId, setSelectedScmUserOrgId] = useState('');

    useEffect(() => {
        if (data) {
            setSelectedScmUserOrgId(data.scmUserOrgs[0].id);
        }
    }, [data]);

    const handleUserOrgChange = (event) => {
        setSelectedScmUserOrgId(event.target.value);
    };

    useEffect(() => {
        if (savedData && savedData.scmUserOrgConfirmSlack) {
            navigate('/');
        }
    }, [savedData]);

    const handleConfirmSlack = async () => {
        await confirmSlack({
            variables: {
                scmUserOrgId: selectedScmUserOrgId,
            },
        });
    };

    return (
        <Page title="Confirm Slack Connection">
            <Container>
                <ContentStyle sx={{ textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', py: 4 }}>
                    <Typography variant="h3" paragraph>
                        Confirm Slack Connection
                    </Typography>
                    <Typography variant="h7" paragraph>
                        Please double check this is the correct connection.
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ mt: 4, alignItems: 'center' }}>
                        <FormControl>
                            <InputLabel id="confirm-slack-label">Org</InputLabel>
                            <Select
                                labelId="confirm-slack-label"
                                id="confirm-slack"
                                value={selectedScmUserOrgId}
                                label="Org"
                                onChange={handleUserOrgChange}
                            >
                                {data &&
                                    data.scmUserOrgs.map((scmUserOrg) => {
                                        return (
                                            <MenuItem key={scmUserOrg.id} value={scmUserOrg.id}>
                                                {scmUserOrg.scmOrg.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <ArrowRight />
                        <span>{data && data.tempSlackUserData && data.tempSlackUserData.slackTeamName}</span>
                    </Stack>

                    <Stack spacing={2} direction="row" sx={{ mt: 4, justifyContent: 'center', textAlign: 'center' }}>
                        <Button
                            onClick={handleConfirmSlack}
                            sx={{ height: 60, width: 120 }}
                            variant="contained"
                            component={Link}
                        >
                            Confirm
                        </Button>
                        <Button to="/" size="large" variant="text" color="success" component={RouterLink}>
                            Cancel
                        </Button>
                    </Stack>
                </ContentStyle>
            </Container>
        </Page>
    );
}
