import { Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { SeverityPill } from '../../components/severity-pill';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';

const statusToPill = (status) => {
    switch (status) {
        case 'fail':
            return <SeverityPill color="error">Fail</SeverityPill>;
        case 'error':
            return <SeverityPill color="error">Error</SeverityPill>;
        case 'pending':
            return <SeverityPill color="info">Pending</SeverityPill>;
        case 'skipped':
            return <SeverityPill color="primary">Skipped</SeverityPill>;
        default:
            return <SeverityPill color="success">Success</SeverityPill>;
    }
};

export const StatusCheckTable = ({ rows }) => {
    const theme = useTheme();

    const tableRows = rows.map((row) => (
        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <Link target="_blank" href={row.detailUrl} rel="noreferrer" underline="none">
                    <Typography color={theme.palette.common.white} fontWeight="bold">
                        {row.name} {row.required && '(Required)'}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell>{statusToPill(row.status)}</TableCell>
            <TableCell>{row.completedOn ? format(new Date(row.completedOn), 'MMM, dd HH:mm') : '-'}</TableCell>
        </TableRow>
    ));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Check Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Completed On</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
        </TableContainer>
    );
};
