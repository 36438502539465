import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const initialState = {
    user: null,
    loading: false,
};
export const USER_QUERY = gql`
    {
        me {
            id
            avatarUrl
            username
            userOrgs {
                id
                slackUserInfoId
                isOrgAdmin
                scmOrg {
                    id
                    name
                    scmType
                    displayName
                    avatarUrl
                    dataLoadStatus
                    slackTeamInfoId
                    slackTeamInfo {
                        id
                        name
                    }
                }
            }
        }
        userSettings {
            timezone
        }
    }
`;

const SET_TIMEZONE = gql`
    mutation SetTimezone($data: UpdateSettingsInput!) {
        updateSettings(data: $data) {
            id
        }
    }
`;

export const AuthContext = createContext({
    ...initialState,
});

export const AuthProvider = ({ children }) => {
    const { loading, data, refetch } = useQuery(USER_QUERY);
    const [setTimezone, { data: timezoneData }] = useMutation(SET_TIMEZONE);

    useEffect(() => {
        if (data) {
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/[/-]/g, '_');
            const savedTimezone = data.userSettings.timezone;
            if (!savedTimezone || savedTimezone !== currentTimezone) {
                setTimezone({
                    variables: {
                        data: {
                            timezone: currentTimezone,
                        },
                    },
                });
            }
        }
    }, [data]);

    // useEffect(() => {
    //     if (timezoneData) {
    //         // When we get timezone data back, refetch
    //        refetch();
    //     }
    //
    // }, [timezoneData]);

    return (
        <AuthContext.Provider
            value={{
                loading,
                refetch,
                user: data == null ? null : data.me,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
