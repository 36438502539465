import { Button } from '@mui/material';
import { GitHub } from '@mui/icons-material';

export const GithubLogin = () => {
    return (
        <Button
            href="/api/github/oauth"
            sx={{
                width: 320,
                height: 70,
                fontSize: 20,
            }}
            variant="contained"
            size="large"
            startIcon={<GitHub />}
        >
            Login with Github
        </Button>
    );
};
