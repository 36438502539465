// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container,
    Typography,
    TableContainer,
    Link,
    Tooltip,
    Box,
    CircularProgress,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { formatDistance, formatDistanceToNowStrict } from 'date-fns';
import { format } from 'date-fns';
// components
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import Page from '../components/Page';
import { useEffect, useMemo, useState } from 'react';
import useWindowFocus from '../hooks/useWindowFocus';
import { SeverityPill } from '../components/severity-pill.jsx';

const REVIEW_QUERY = gql`
    {
        pullRequestReviewInfos {
            id
            status
            requestedOn
            dueOn
            pullRequestInfo {
                author {
                    username
                }
                openedOn
                htmlUrl
                scmRepo {
                    name
                }
                title
                isDraft
                scmOrg {
                    name
                }
            }
            lastReviewRound {
                id
                status
                requestedOn
                status
            }
        }
    }
`;

export default function NeedsReview() {
    const { loading, data, stopPolling, startPolling, refetch, networkStatus } = useQuery(REVIEW_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });
    const windowFocused = useWindowFocus();
    const [reviewFilter, setReviewFilter] = useState('open');

    const dataLoadedDate = useMemo(() => {
        return new Date();
    }, [data]);

    useEffect(() => {
        if (windowFocused) {
            if (!loading) {
                refetch();
            }

            startPolling(30000);
        } else {
            stopPolling();
        }
    }, [windowFocused, refetch, startPolling, stopPolling]);

    const rows = useMemo(() => {
        let pullRequestInfos = data ? data.pullRequestReviewInfos : [];

        if (reviewFilter === 'open') {
            pullRequestInfos = pullRequestInfos.filter((item) => item.lastReviewRound.status === 'active');
        } else if (reviewFilter === 'completed') {
            pullRequestInfos = pullRequestInfos.filter((item) => item.lastReviewRound.status !== 'active');
        }

        return pullRequestInfos;
    }, [data?.pullRequestReviewInfos, reviewFilter]);

    const now = new Date();

    const table = () => {
        let tableRows;
        let showHeader = true;
        if (!data) {
            showHeader = false;
            tableRows = Array(3)
                .fill(0)
                .map((_, index) => (
                    <TableRow key={`loading_${index}`}>
                        <TableCell>
                            <Skeleton variant="rectangular" height={75} />
                        </TableCell>
                    </TableRow>
                ));
        } else if (rows.length === 0) {
            showHeader = false;
            tableRows = [
                <TableRow key="none">
                    <TableCell sx={{ py: 4, fontWeight: 'bold', fontSize: '1.2rem' }} style={{ textAlign: 'center' }}>
                        No reviews
                    </TableCell>
                </TableRow>,
            ];
        } else {
            tableRows = rows.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                        <div>
                            <Link target="_blank" href={row.pullRequestInfo.htmlUrl} rel="noreferrer" underline="none">
                                <Typography fontWeight="bold" fontSize={16}>
                                    {row.pullRequestInfo.isDraft && (
                                        <span>
                                            <SeverityPill color="info">Draft</SeverityPill>{' '}
                                        </span>
                                    )}
                                    {row.pullRequestInfo.title}
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            {row.pullRequestInfo.scmOrg.name}/{row.pullRequestInfo.scmRepo.name}
                        </div>
                    </TableCell>
                    <TableCell>{row.pullRequestInfo.author.username}</TableCell>
                    <TableCell>
                        <Tooltip title={row.dueOn ? format(new Date(row.dueOn), 'MMM, dd, yyyy HH:mm') : '-'}>
                            <Box>{row.dueOn ? formatDistance(new Date(row.dueOn), now, { addSuffix: true }) : '-'}</Box>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip title={format(new Date(row.lastReviewRound.requestedOn), 'MMM, dd, yyyy HH:mm')}>
                            <Box>
                                {formatDistance(new Date(row.lastReviewRound.requestedOn), now, { addSuffix: true })}
                            </Box>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip title={format(new Date(row.requestedOn), 'MMM, dd, yyyy HH:mm')}>
                            <Box>{formatDistance(new Date(row.requestedOn), now, { addSuffix: true })}</Box>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ));
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {showHeader ? (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Author</TableCell>
                                <TableCell>Due</TableCell>
                                <TableCell>Requested</TableCell>
                                <TableCell>Review Started</TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell sx={{ height: 42 }}></TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Page title="Needs review">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Open Reviews
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 140 }}>
                        <InputLabel id="review-filter-label">Review Status</InputLabel>
                        <Select
                            labelId="review-filter-label"
                            id="review-filter"
                            value={reviewFilter}
                            label="Review Status"
                            onChange={(event) => {
                                setReviewFilter(event.target.value);
                            }}
                        >
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="both">All</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="end" mb={0.5}>
                    {[NetworkStatus.refetch, NetworkStatus.poll].includes(networkStatus) ? (
                        <CircularProgress sx={{ mr: 1 }} size={10} />
                    ) : null}
                    <Tooltip title={formatDistanceToNowStrict(dataLoadedDate, { addSuffix: true })}>
                        <Box sx={{ fontSize: 10 }}>Last Updated: {format(dataLoadedDate, 'HH:mm:ss yyyy-MM-dd')}</Box>
                    </Tooltip>
                </Stack>
                <Card>{table()}</Card>
            </Container>
        </Page>
    );
}
