import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
    return <img src="/static/logos/logo.png" {...props} />;
})``;

Logo.defaultProps = {
    variant: 'primary',
};

Logo.propTypes = {
    variant: PropTypes.oneOf(['light', 'primary']),
};
