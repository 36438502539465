import { useMemo, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Avatar, Badge, Box, ButtonBase, IconButton, Toolbar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '../icons/menu';
import { Bell as BellIcon } from '../icons/bell';
import { UserCircle as UserCircleIcon } from '.././icons/user-circle';
import { AccountPopover } from './account-popover';
import { NotificationsPopover } from './notifications-popover';
import { useAuth } from '../hooks/use-auth';
import { ScmOrgIdContext } from '../contexts/scm_user_org_id_context.jsx';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    //
    ...(theme.palette.mode === 'light'
        ? {
              boxShadow: 'none',
              backgroundColor: 'transparent',
              borderBottomColor: 'transparent',
              borderBottomStyle: 'solid',
              borderBottomWidth: 0,
          }
        : {
              backgroundColor: 'transparent',
              borderBottomColor: 'transparent',
              borderBottomStyle: 'solid',
              borderBottomWidth: 0,
              boxShadow: 'none',
          }),
}));

const NotificationsButton = () => {
    const anchorRef = useRef(null);
    const { user } = useAuth();

    const { scmOrgId } = useContext(ScmOrgIdContext);

    const notifications = useMemo(() => {
        if (!user) {
            return null;
        }
        const items = [];

        // Check for missing Slack connections
        const missingSlackConnections = user.userOrgs.filter((sl) => {
            const matchesOrg = !sl.scmOrgId || sl.scmOrgId === scmOrgId;
            const isAdminOrHasSlackTeam = sl.isOrgAdmin || sl.scmOrg.slackTeamInfoId;

            return matchesOrg && !sl.slackUserInfoId && isAdminOrHasSlackTeam;
        });
        if (missingSlackConnections.length > 0) {
            items.push({
                id: 1,
                title: 'Connect to Slack',
                description: 'to receive notifications about Pull Requests',
                avatar: '/static/logos/slack_mark.svg',
                type: 'connect_so_slack',
                createdAt: new Date(),
                onClick: () => (window.location.href = '/api/slack/oauth'),
                isUnRead: true,
            });
        }
        return items;
    }, [user]);

    const [openPopover, setOpenPopover] = useState(false);
    // Unread notifications should come from a context and be shared with both this component and
    // notifications popover. To simplify the demo, we get it from the popover

    const handleOpenPopover = () => {
        setOpenPopover(true);
    };

    const handleClosePopover = () => {
        setOpenPopover(false);
    };

    const handleUpdateUnread = (value) => {
        console.log('Mark read');
    };

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton ref={anchorRef} sx={{ ml: 1 }} onClick={handleOpenPopover}>
                    <Badge color="error" badgeContent={notifications ? notifications.length : ''}>
                        <BellIcon fontSize="small" />
                    </Badge>
                </IconButton>
            </Tooltip>
            {notifications && (
                <NotificationsPopover
                    notifications={notifications}
                    anchorEl={anchorRef.current}
                    onClose={handleClosePopover}
                    onUpdateUnread={handleUpdateUnread}
                    open={openPopover}
                />
            )}
        </>
    );
};

const AccountButton = () => {
    const anchorRef = useRef(null);
    const { user } = useAuth();
    const [openPopover, setOpenPopover] = useState(false);

    const handleOpenPopover = () => {
        setOpenPopover(true);
    };

    const handleClosePopover = () => {
        setOpenPopover(false);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 2,
                }}
            >
                <Avatar
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                    src={user.avatarUrl}
                >
                    <UserCircleIcon fontSize="small" />
                </Avatar>
            </Box>
            <AccountPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} />
        </>
    );
};

export const DashboardNavbar = (props) => {
    const { onOpenSidebar, ...other } = props;

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280,
                    },
                    width: {
                        lg: 'calc(100% - 280px)',
                    },
                }}
                {...other}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2,
                    }}
                >
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none',
                            },
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <NotificationsButton />
                    <AccountButton />
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};
