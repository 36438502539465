// @mui
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Link } from '@mui/material';
import { Logo } from '../components/logo.jsx';
// components

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 600,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function NotInstalled() {
    return (
        <Container sx={{ textAlign: 'center', alignItems: 'center' }}>
            <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', py: 4 }}>
                <Stack direction="row" alignItems="center">
                    <Logo
                        sx={{
                            height: 100,
                            width: 100,
                        }}
                    />
                    <Typography variant="h3" paragraph>
                        The PullNotify is not installed.
                    </Typography>
                </Stack>
                <Button
                    sx={{ width: '100%', py: 2, mt: 3 }}
                    size="large"
                    variant="contained"
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/api/github/marketplace"
                >
                    Install App
                </Button>
            </ContentStyle>
        </Container>
    );
}
