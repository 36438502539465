import { useRef, useState } from 'react';
import { Stack, Box, Typography, Popover, Button } from '@mui/material';

export const DisconnectButton = ({ onDisconnect }) => {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="text" color="error" ref={anchorEl}>
                Disconnect
            </Button>
            <Popover
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={!!open}
                PaperProps={{ sx: { width: 248 } }}
                transitionDuration={0}
            >
                <Box sx={{ p: 2 }}>
                    <Box sx={{ mb: 4 }}>
                        <Typography fontWeight="bold" variant="h7">
                            Are you sure you want to disconnect this Slack connection?
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={onDisconnect} variant="contained" color="error">
                            Disconnect
                        </Button>
                    </Stack>
                </Box>
            </Popover>
        </>
    );
};
