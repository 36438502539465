// material
import { Box, Container, Tab, Tabs } from '@mui/material';
// components
import Page from '../../components/Page';
import { useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

const usersOverTimePath = '/dashboard/stats/comparison';
const allUsersPath = '/dashboard/stats/all-users';

export default function Stats() {
    let compareUsersMatch = useMatch({
        path: allUsersPath,
    });
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState(!compareUsersMatch ? 'comparison' : 'all-users');

    const handleChange = (e, value) => {
        navigate(value === 'comparison' ? usersOverTimePath : allUsersPath);
        setSelectedTab(value);
    };

    return (
        <Page title="Stats">
            <Container>
                <Tabs aria-label="basic tabs example" value={selectedTab} onChange={handleChange}>
                    <Tab label="Charts" value="comparison" />
                    <Tab label="All Users" value="all-users" />
                </Tabs>
                <Box sx={{ mt: 4 }}>
                    <Outlet />
                </Box>
            </Container>
        </Page>
    );
}
