// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Link,
    Tooltip,
    Box,
    IconButton,
    CircularProgress,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { formatDistanceToNowStrict, format } from 'date-fns';
// components
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { useMemo, useState, useRef, useEffect, useContext } from 'react';
import { SeverityPill } from '../../components/severity-pill';
import { Skeleton } from '@mui/material';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { DetailDrawer } from '../../components/detail_drawer';
import { ReviewsDetailTable } from './ReviewsDetailTable';
import { X as XIcon } from '../../icons/x';
import { StatusCheckTable } from './StatusCheckTable';
import Page from '../../components/Page';
import useWindowFocus from '../../hooks/useWindowFocus';
import { ScmOrgIdContext } from '../../contexts/scm_user_org_id_context.jsx';

const PRS_QUERY = gql`
    {
        pullRequestInfos {
            id
            reviews {
                id
                reviewer {
                    username
                }
                requestedOn
                dueOn
                status
                lastReviewRound {
                    status
                    completedOn
                    htmlUrl
                }
            }
            commitStatuses {
                id
                status
                detailUrl
                name
                completedOn
                required
            }
            destinationScmBranch {
                requiredCommitStatusNames
            }
            scmRepo {
                name
            }
            scmOrg {
                name
            }
            openedOn
            mergeable
            htmlUrl
            title
            isDraft
        }
    }
`;

const getLastDueDate = (reviewInfos) => {
    if (reviewInfos.length === 0) {
        return 'No reviews';
    }

    const anyFailingReviews = reviewInfos.some((review) =>
        ['comment', 'changes_requested'].includes(review.lastReviewRound.status),
    );
    if (anyFailingReviews) {
        return 'Waiting for rerequest';
    }

    const reviewsApproved = reviewInfos.every((review) => review.lastReviewRound.status === 'approved');
    if (reviewsApproved) {
        return 'Reviews Completed';
    }

    const allNoDueDate = reviewInfos.every((review) => !review.dueOn);
    if (allNoDueDate) {
        return 'Pending';
    }

    return reviewInfos
        .filter((review) => review.dueOn)
        .map((reviewInfo) => new Date(reviewInfo.dueOn))
        .reduce((a, b) => (a > b ? a : b));
};

const commitSuccessStatus = 'success';
const commitFailStatuses = ['fail', 'error'];
const commitPendingStatus = 'pending';

const getCommitStatusData = (commitStatuses) => {
    let successCount = commitStatuses.reduce(
        (count, item) => (item.status === commitSuccessStatus ? count + 1 : count),
        0,
    );
    let failCount = commitStatuses.reduce(
        (count, item) => (commitFailStatuses.includes(item.status) ? count + 1 : count),
        0,
    );
    let pendingCount = commitStatuses.reduce(
        (count, item) => (item.status === commitPendingStatus ? count + 1 : count),
        0,
    );

    return {
        successCount,
        failCount,
        pendingCount,
    };
};

const reviewStatusCompleted = 'completed';
const reviewStatusActive = 'active';
const reviewRoundStatusSuccess = 'approved';
const reviewRoundStatusesFail = ['changes_requested', 'comment'];

const getReviewsData = (reviews) => {
    let successCount = reviews.reduce(
        (count, item) =>
            item.status === reviewStatusCompleted ||
            (item.lastReviewRound && item.lastReviewRound.status === reviewRoundStatusSuccess)
                ? count + 1
                : count,
        0,
    );
    let failCount = reviews.reduce(
        (count, item) =>
            item.status === reviewStatusActive &&
            item.lastReviewRound &&
            reviewRoundStatusesFail.includes(item.lastReviewRound.status)
                ? count + 1
                : count,
        0,
    );
    let pendingCount = reviews.reduce(
        (count, item) =>
            item.status === reviewStatusActive &&
            (!item.lastReviewRound || item.lastReviewRound.status === reviewStatusActive)
                ? count + 1
                : count,
        0,
    );

    return {
        successCount,
        failCount,
        pendingCount,
    };
};

export default function CreatedReviews() {
    const { loading, data, refetch, startPolling, stopPolling, networkStatus } = useQuery(PRS_QUERY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const { scmUserOrgId } = useContext(ScmOrgIdContext);
    const rootRef = useRef(null);
    const theme = useTheme();
    const [drawerData, setDrawerData] = useState(null);
    const windowFocused = useWindowFocus();

    const chartOptions = useMemo(() => {
        if (!theme) {
            return null;
        }

        return {
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
            },
            colors: [theme.palette.success.main, theme.palette.grey.A700, theme.palette.error.main],

            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            legend: {
                show: false,
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    },
                },
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                },
            },
        };
    }, [theme]);

    useEffect(() => {
        if (windowFocused) {
            if (!loading) {
                refetch();
            }

            startPolling(30000);
        } else {
            stopPolling();
        }
    }, [windowFocused, refetch, startPolling, stopPolling]);

    const dataLoadedDate = useMemo(() => {
        return new Date();
    }, [data]);

    const rows = data ? data.pullRequestInfos : [];
    const handleDetail = (data, e) => {
        if (!data.rows.length) {
            return;
        }
        e.stopPropagation();
        setDrawerData(data);
    };
    const statusesWithRequired = (commitStatuses, requiredCommitStatusNames) => {
        return commitStatuses.map((commitStatus) => ({
            ...commitStatus,
            required: requiredCommitStatusNames && requiredCommitStatusNames.includes(commitStatus.name),
        }));
    };

    const table = () => {
        let tableRows;
        let showHeader = true;
        if (!data) {
            showHeader = false;
            tableRows = Array(3)
                .fill(0)
                .map((_, index) => (
                    <TableRow key={`loading_${index}`}>
                        <TableCell>
                            <Skeleton variant="rectangular" height={75} />
                        </TableCell>
                    </TableRow>
                ));
        } else if (!rows.length) {
            showHeader = false;
            tableRows = [
                <TableRow key="none">
                    <TableCell sx={{ py: 4, fontWeight: 'bold', fontSize: '1.2rem' }} style={{ textAlign: 'center' }}>
                        No created reviews
                    </TableCell>
                </TableRow>,
            ];
        } else {
            tableRows = rows.map((row) => {
                const lastDueDate = getLastDueDate(row.reviews);
                const commitResults = getCommitStatusData(row.commitStatuses);
                const reviewResults = getReviewsData(row.reviews);

                return (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            <div>
                                <Link target="_blank" href={row.htmlUrl} rel="noreferrer" underline="none">
                                    <Typography fontWeight="bold" fontSize={16}>
                                        {row.isDraft && (
                                            <span>
                                                <SeverityPill color="info">Draft</SeverityPill>{' '}
                                            </span>
                                        )}
                                        {row.title}
                                    </Typography>
                                </Link>
                            </div>
                            <div>
                                {row.scmOrg.name}/{row.scmRepo.name}
                            </div>
                        </TableCell>
                        <TableCell>
                            {row.mergeable ? (
                                <SeverityPill color="success">Yes</SeverityPill>
                            ) : row.mergeable === false ? (
                                <SeverityPill color="error">No</SeverityPill>
                            ) : (
                                <SeverityPill color="info">Waiting for status</SeverityPill>
                            )}
                        </TableCell>
                        <TableCell>
                            <Tooltip
                                title={`${commitResults.successCount} succeeded,  ${commitResults.failCount} failed, ${commitResults.pendingCount} pending`}
                            >
                                <Box
                                    sx={{ cursor: row.commitStatuses.length ? 'pointer' : '' }}
                                    onClick={(e) =>
                                        handleDetail(
                                            {
                                                type: 'Status Checks',
                                                rows: statusesWithRequired(
                                                    row.commitStatuses,
                                                    row.destinationScmBranch.requiredCommitStatusNames,
                                                ),
                                            },
                                            e,
                                        )
                                    }
                                >
                                    {chartOptions && (
                                        <Chart
                                            height={60}
                                            width={60}
                                            options={chartOptions}
                                            series={[
                                                commitResults.successCount,
                                                row.commitStatuses.length ? commitResults.pendingCount : 1,
                                                commitResults.failCount,
                                            ]}
                                            type="pie"
                                        />
                                    )}
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                            <Tooltip
                                title={`${reviewResults.successCount} succeeded,  ${reviewResults.failCount} failed, ${reviewResults.pendingCount} pending`}
                            >
                                <Box
                                    sx={{ cursor: row.reviews.length ? 'pointer' : '' }}
                                    onClick={(e) => handleDetail({ type: 'Reviews', rows: row.reviews }, e)}
                                >
                                    {chartOptions && (
                                        <Chart
                                            height={60}
                                            width={60}
                                            options={chartOptions}
                                            series={[
                                                reviewResults.successCount,
                                                row.reviews.length ? reviewResults.pendingCount : 1,
                                                reviewResults.failCount,
                                            ]}
                                            type="pie"
                                        />
                                    )}
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip
                                disabled={typeof lastDueDate === 'string'}
                                title={
                                    typeof lastDueDate === 'string'
                                        ? ''
                                        : formatDistanceToNowStrict(lastDueDate, { unit: 'hour', addSuffix: true })
                                }
                            >
                                <Typography>
                                    {typeof lastDueDate === 'string'
                                        ? lastDueDate
                                        : format(lastDueDate, 'MMM, dd HH:mm')}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                            <Tooltip
                                title={`${formatDistanceToNowStrict(new Date(row.openedOn), { addSuffix: true })}`}
                            >
                                <Box>{format(new Date(row.openedOn), 'MMM, dd HH:mm')}</Box>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                );
            });
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {showHeader ? (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Mergeable</TableCell>
                                <TableCell>Status Checks</TableCell>
                                <TableCell>Reviews</TableCell>
                                <TableCell>Review Due</TableCell>
                                <TableCell align="right">PR Opened</TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell sx={{ height: 42 }}></TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Page title="Created Reviews">
            <Box component="main" ref={rootRef}>
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <Typography variant="h4" gutterBottom>
                            PRs Created
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="end" mb={0.5}>
                        {[NetworkStatus.refetch, NetworkStatus.poll].includes(networkStatus) ? (
                            <CircularProgress sx={{ mr: 1 }} size={10} />
                        ) : null}
                        <Tooltip title={formatDistanceToNowStrict(dataLoadedDate, { addSuffix: true })}>
                            <Box sx={{ fontSize: 10 }}>
                                Last Updated: {format(dataLoadedDate, 'HH:mm:ss yyyy-MM-dd')}
                            </Box>
                        </Tooltip>
                    </Stack>
                    <Card>{table()}</Card>
                </Container>
                <DetailDrawer open={!!drawerData} onClose={() => setDrawerData(null)}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: 3,
                            py: 2,
                        }}
                    >
                        <Typography color="inherit" variant="h6">
                            {drawerData && drawerData.type}
                        </Typography>
                        <IconButton color="inherit" onClick={() => setDrawerData(null)}>
                            <XIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    {drawerData &&
                        (drawerData.type === 'Reviews' ? (
                            <ReviewsDetailTable rows={drawerData.rows} />
                        ) : (
                            <StatusCheckTable rows={drawerData.rows} />
                        ))}
                </DetailDrawer>
            </Box>
        </Page>
    );
}
