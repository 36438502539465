import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { Box } from '@mui/material';
import { Outlet, useSearchParams } from 'react-router-dom';
import { ErrorBoundary } from '../components/error_boundary';
import { AuthProvider } from '../contexts/cookie_context.jsx';
import { ScmOrgIdContext } from '../contexts/scm_user_org_id_context.jsx';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280,
    },
}));

export const DashboardLayout = (props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [scmOrgId, setScmOrgId] = useState(searchParams.get('scmOrgId') || '');

    useEffect(() => {
        if (scmOrgId) {
            setSearchParams({ scmOrgId: scmOrgId, ...Object.fromEntries(searchParams) });
        } else {
            searchParams.delete('scmOrgId');
            setSearchParams(searchParams);
        }
    }, [scmOrgId]);

    return (
        <>
            <AuthProvider>
                <ScmOrgIdContext.Provider value={{ scmOrgId, setScmOrgId }}>
                    <DashboardLayoutRoot>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: '1 1 auto',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <ErrorBoundary>
                                <Outlet />
                            </ErrorBoundary>
                        </Box>
                    </DashboardLayoutRoot>
                    <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
                    <DashboardSidebar onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen} />
                </ScmOrgIdContext.Provider>
            </AuthProvider>
        </>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node,
};
