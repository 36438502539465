import { Box, Card, Container, Typography } from '@mui/material';
import { Logo } from '../components/logo';
import { GithubLogin } from '../components/github-login';
import Page from '../components/Page';

const Login = () => {
    return (
        <Page title="Login | PullNotify">
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: '60px',
                            md: '120px',
                        },
                    }}
                >
                    <Card elevation={16} sx={{ p: 4 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40,
                                }}
                            />
                            <Typography variant="h4" sx={{ mb: 2 }}>
                                Login to PullNotify
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3,
                                textAlign: 'center',
                            }}
                        >
                            <GithubLogin />
                        </Box>
                    </Card>
                </Container>
            </Box>
        </Page>
    );
};

export default Login;
