import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../hooks/use-auth';
import { Cog as CogIcon } from '../icons/cog';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { useNavigate } from 'react-router-dom';

export const AccountPopover = (props) => {
    const { anchorEl, onClose, open, ...other } = props;
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        onClose?.();
        await fetch('/api/logout', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ logout: true }), // body data type must match "Content-Type" header
        });
        navigate('/login');
    };

    if (!user) {
        return null;
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 300 } }}
            transitionDuration={0}
            {...other}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    p: 2,
                    display: 'flex',
                }}
            >
                <Avatar
                    src={user.avatarUrl}
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                >
                    <UserCircleIcon fontSize="small" />
                </Avatar>
                <Box
                    sx={{
                        ml: 1,
                    }}
                >
                    <Typography variant="body1">{user.displayName || user.username}</Typography>
                    {user.displayName && (
                        <Typography color="textSecondary" variant="body2">
                            {user.username}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Divider />
            <Box sx={{ my: 1 }}>
                <MenuItem component={NavLink} onClick={onClose} to="/dashboard/settings">
                    <ListItemIcon>
                        <CogIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1">Settings</Typography>} />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
                </MenuItem>
            </Box>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
