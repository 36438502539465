import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography } from '@mui/material';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { Scrollbar } from '../components/scrollbar';

const getNotificationContent = (notification) => {
    return (
        <>
            <ListItemAvatar sx={{ mt: 0.5 }}>
                <Avatar src={notification.avatar}>
                    <UserCircleIcon fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            m: 0,
                        }}
                    >
                        <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                            {notification.title}
                        </Typography>
                        <Typography sx={{ mr: 0.5 }} variant="body2">
                            {notification.description}
                        </Typography>
                    </Box>
                }
                secondary={
                    <Typography color="textSecondary" variant="caption">
                        {format(notification.createdAt, 'MMM dd, h:mm a')}
                    </Typography>
                }
                sx={{ my: 0 }}
            />
        </>
    );
};

export const NotificationsPopover = ({ anchorEl, notifications, onClose, onUpdateUnread, open, ...other }) => {
    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 380 } }}
            transitionDuration={0}
            {...other}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2,
                }}
            >
                <Typography color="inherit" variant="h6">
                    Notifications
                </Typography>
            </Box>
            {notifications.length === 0 ? (
                <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle2">There are no notifications</Typography>
                </Box>
            ) : (
                <Scrollbar sx={{ maxHeight: 400 }}>
                    <List disablePadding>
                        {notifications.map((notification) => (
                            <ListItem
                                divider
                                key={notification.id}
                                onClick={notification.onClick}
                                sx={{
                                    cursor: 'pointer',
                                    alignItems: 'flex-start',
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                    },
                                    '& .MuiListItemSecondaryAction-root': {
                                        top: '24%',
                                    },
                                }}
                                // secondaryAction={(
                                //   <Tooltip title="Remove">
                                //     <IconButton
                                //       edge="end"
                                //       onClick={() => handleRemoveOne(notification.id)}
                                //       size="small"
                                //     >
                                //       <XIcon sx={{ fontSize: 14 }} />
                                //     </IconButton>
                                //   </Tooltip>
                                // )}
                            >
                                {getNotificationContent(notification)}
                            </ListItem>
                        ))}
                    </List>
                </Scrollbar>
            )}
        </Popover>
    );
};

NotificationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    onUpdateUnread: PropTypes.func,
    open: PropTypes.bool,
};
