// routes
import Router from './routes';
// theme
// components
import { baseThemeOptions } from './theme/base_theme_options';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material';
import { darkThemeOptions } from './theme/dark_theme_options';
import { lightThemeOptions } from './theme/light_theme_options';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './contexts/cookie_context.jsx';

export const createCurrentTheme = (config) => {
    let theme = createTheme(baseThemeOptions, config.mode === 'dark' ? darkThemeOptions : lightThemeOptions);

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ThemeProvider
            theme={createCurrentTheme({
                responsiveFontSizes: false,
                mode: 'dark',
            })}
        >
            <Router />
            <CssBaseline />
        </ThemeProvider>
    );
}
