import { useEffect, useMemo, useState } from 'react';
import { STATS_OPTIONS } from './constants/stats_options';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';
import { format, parse } from 'date-fns';
import { isEqual, sortBy, uniqWith } from 'lodash';

const USER_STAT_QUERY = gql`
    query UserStatOverTime(
        $name: StatName!
        $userInfoIds: [String!]!
        $teamIds: [String!]!
        $grouping: ReportingTimeGrouping!
    ) {
        userStatsOverTime(name: $name, userInfoIds: $userInfoIds, teamIds: $teamIds, grouping: $grouping) {
            userId
            month
            value
        }
    }
`;

const USER_TEAM_QUERY = gql`
    {
        orgUserInfos {
            id
            username
            displayName
        }
        orgTeams {
            id
            name
        }
    }
`;

const GROUPING_OPTIONS = [
    {
        label: 'Monthly',
        value: 'MONTHLY',
    },
    {
        label: 'Every 4 weeks',
        value: 'EVERY_4_WEEKS',
    },
    {
        label: 'Every 2 weeks',
        value: 'EVERY_2_WEEKS',
    },
];

export default function ChartStats() {
    const theme = useTheme();
    const [search, setSearch] = useSearchParams();
    const stats = STATS_OPTIONS.filter((stat) => stat.excludeUserStat !== true);
    const defaultStat = stats.find((statOption) => statOption.value === search.get('stat')) || stats[0];
    const defaultGrouping =
        GROUPING_OPTIONS.find((groupingOption) => groupingOption.value === search.get('grouping')) ||
        GROUPING_OPTIONS[0];

    const [selectedStat, setSelectedStat] = useState(defaultStat);
    const [selectedGrouping, setSelectedGrouping] = useState(defaultGrouping);
    const [selectedUsersTeams, setSelectedUsersTeams] = useState(null);

    const [queryStat, { data }] = useLazyQuery(USER_STAT_QUERY);
    const { data: usersTeamData } = useQuery(USER_TEAM_QUERY);

    function getUserTeamIds(selectedItems) {
        const userInfoIds = selectedItems.filter((item) => !item.id.startsWith('team:')).map((item) => item.id);
        const teamIds = selectedItems
            .filter((item) => item.id.startsWith('team:'))
            .map((item) => item.id.split('team:')[1]);

        return {
            userInfoIds,
            teamIds,
        };
    }

    const handleStatChange = ({ target }) => {
        const newStat = stats.find((stat) => stat.value === target.value);

        setSelectedStat(newStat);
        const { userInfoIds, teamIds } = getUserTeamIds(selectedUsersTeams);
        queryStat({ variables: { name: newStat.value, userInfoIds, teamIds, grouping: selectedGrouping.value } });
    };

    const handleGroupingChange = ({ target }) => {
        const newGrouping = GROUPING_OPTIONS.find((grouping) => grouping.value === target.value);

        setSelectedGrouping(newGrouping);
        const { userInfoIds, teamIds } = getUserTeamIds(selectedUsersTeams);
        queryStat({ variables: { name: selectedStat.value, userInfoIds, teamIds, grouping: newGrouping.value } });
    };

    const availableUsersAndTeams = useMemo(() => {
        if (!usersTeamData) {
            return null;
        }

        const users = usersTeamData.orgUserInfos.map((user) => ({
            id: user.id,
            // if display name is present, show the display name with username in parens. otherwise just username
            label: user.displayName ? `${user.displayName} (${user.username})` : user.username,
        }));

        const teams = usersTeamData.orgTeams.map((team) => ({
            id: `team:${team.id}`,
            label: `Team: ${team.name}`,
        }));

        return users.concat(teams);
    }, [usersTeamData]);

    useEffect(() => {
        if (!availableUsersAndTeams || !availableUsersAndTeams.length) {
            return;
        }

        let userTeams;
        if (search.get('userIds')) {
            const userIds = search.get('userIds').split(',');
            userTeams = availableUsersAndTeams.filter((user) => userIds.includes(user.id));
        }

        if (!userTeams) {
            userTeams = [];
        }

        if (isEqual(selectedUsersTeams, userTeams)) {
            // Don't do anything if teams are equal
            return;
        }

        setSelectedUsersTeams(userTeams);

        if (userTeams.length) {
            const { userInfoIds, teamIds } = getUserTeamIds(userTeams);

            queryStat({
                variables: { name: selectedStat.value, userInfoIds, teamIds, grouping: selectedGrouping.value },
            });
        }
    }, [availableUsersAndTeams, search, queryStat]);

    useEffect(() => {
        if (selectedUsersTeams && selectedStat) {
            setSearch({
                ...Object.fromEntries(search),
                userIds: selectedUsersTeams.map((user) => user.id).join(','),
                stat: selectedStat.value,
                grouping: selectedGrouping.value,
            });
        }
    }, [selectedUsersTeams, selectedStat, setSearch, setSelectedGrouping, selectedGrouping]);

    const handleUserChange = (e, selectedItems) => {
        if (!selectedItems) {
            return;
        }

        setSelectedUsersTeams(selectedItems);
        const { userInfoIds, teamIds } = getUserTeamIds(selectedItems);

        queryStat({ variables: { name: selectedStat.value, userInfoIds, teamIds, grouping: selectedGrouping.value } });
    };

    const { seriesData, categories } = useMemo(() => {
        if (!data) {
            return {};
        }

        // Get all the unique dates and sort them
        let categories = uniqWith(
            data.userStatsOverTime.map((item) => parse(item.month, 'MM/dd/yyyy', new Date(2022))),
            isEqual,
        );
        categories = sortBy(categories).map((date) => format(date, 'MM/dd/yyyy'));

        // Get all the unique usernames
        const userIdData = data.userStatsOverTime.reduce((prev, item) => {
            if (!prev[item.userId]) {
                prev[item.userId] = {};
            }
            prev[item.userId][item.month] = item.value;
            return prev;
        }, {});

        const userIdMap = selectedUsersTeams.reduce((prev, item) => {
            if (item.id.startsWith('team:')) {
                prev[item.id.split('team:')[1]] = item.label;
            } else {
                prev[item.id] = item.label;
            }

            return prev;
        }, {});

        return {
            seriesData: Object.keys(userIdData).map((userId) => ({
                name: userIdMap[userId] ?? userId,
                data: categories.map((category) => userIdData[userId][category] ?? null),
            })),
            categories,
        };
    }, [data]);

    const chartOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [],
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'date',
                        headerValue: selectedStat.valueName,
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        },
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    },
                },
                autoSelected: 'zoom',
            },
        },
        colors: ['#0C7CD5', '#B27B16', '#0B815A', '#D14343', '#0E8074', '#3832A0'],
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 1,
        },
        grid: {
            borderColor: theme.palette.divider,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        legend: {
            position: 'bottom',
            showForSingleSeries: true,
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        markers: {
            hover: {
                size: undefined,
                sizeOffset: 2,
            },
            radius: 2,
            shape: 'circle',
            size: 4,
            strokeWidth: 0,
        },
        stroke: {
            // curve: 'smooth',
            lineCap: 'butt',
            width: 3,
        },
        theme: {
            mode: theme.palette.mode,
        },
        xaxis: {
            type: 'category',
            categories,
            axisBorder: {
                color: theme.palette.divider,
            },
            axisTicks: {
                color: theme.palette.divider,
                show: true,
            },
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                },
            },
        },
        yaxis: [
            {
                title: {
                    text: selectedStat.valueName,
                },
                axisBorder: {
                    color: theme.palette.divider,
                    show: true,
                },
                axisTicks: {
                    color: theme.palette.divider,
                    show: true,
                },
                labels: {
                    style: {
                        colors: theme.palette.text.secondary,
                    },
                },
                decimalsInFloat: 2,
            },
        ],
    };

    let resultElement;
    if (!selectedUsersTeams || !selectedUsersTeams.length) {
        resultElement = <Typography variant="h5">Select users to chart stats</Typography>;
    } else if (seriesData && seriesData.length > 0 && seriesData[0].data.length > 0) {
        resultElement = (
            <Box sx={{ height: 500 }}>
                <Chart height={500} options={chartOptions} series={seriesData} type="line" />
            </Box>
        );
    } else if (seriesData) {
        resultElement = <Typography variant="h5">No data available yet</Typography>;
    }

    return (
        <>
            <FormControl
                sx={{
                    mb: 2,
                    mr: 2,
                }}
            >
                <InputLabel id="demo-simple-select-label">Stat</InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedStat.value}
                    label="Report"
                    onChange={handleStatChange}
                >
                    {stats.map((report) => (
                        <MenuItem key={report.value} value={report.value}>
                            {report.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {availableUsersAndTeams && (
                <FormControl
                    sx={{
                        mb: 2,
                        mr: 2,
                    }}
                >
                    <Autocomplete
                        multiple
                        id="user-search"
                        filterSelectedOptions
                        getOptionLabel={(option) => option.label}
                        sx={{
                            width: 400,
                        }}
                        options={availableUsersAndTeams}
                        value={selectedUsersTeams || []}
                        onChange={handleUserChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                label="Users"
                            />
                        )}
                    />
                </FormControl>
            )}
            <FormControl
                sx={{
                    mb: 2,
                    width: 120,
                }}
            >
                <InputLabel id="grouping-label">Grouping</InputLabel>

                <Select
                    labelId="grouping-label"
                    id="grouping-select"
                    value={selectedGrouping.value}
                    label="Grouping"
                    onChange={handleGroupingChange}
                >
                    {GROUPING_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {resultElement}
        </>
    );
}
