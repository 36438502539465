import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

const positiveColor = '#4caf50';
const negativeColor = '#f44336';
const neutralColor = '#9e9e9e';

export const StatCard = ({ title, data, units, lowerIsBetter = false }) => {
    const current = data[data.length - 1];
    const previous = data[data.length - 2];
    const percentage =
        current != null && previous != null && previous != 0 ? ((current - previous) / previous) * 100 : null;
    const isPositive = percentage >= 0;
    let color;
    if (lowerIsBetter == null) {
        color = neutralColor;
    } else {
        color = isPositive || lowerIsBetter ? positiveColor : negativeColor;
    }

    // Create a configuration object for the ApexCharts
    const chartOptions = {
        chart: {
            type: 'line',
            height: 60,
            sparkline: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        tooltip: {
            enabled: false,
        },
        colors: [color],
    };

    const chartSeries = [{ data }];

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h4">
                            {current != null ? current : 'No data'}
                            {current != null ? units : ''}
                        </Typography>
                        {percentage != null && (
                            <span>
                                {lowerIsBetter != null && (
                                    <span style={{ color, marginLeft: '4px' }}>{isPositive ? '↑' : '↓'}</span>
                                )}
                                <span style={{ marginLeft: '4px', color }}>
                                    {Math.abs(Math.round(percentage * 100) / 100)}%
                                </span>
                            </span>
                        )}
                    </div>
                    <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={60} />
                </CardContent>
            </Card>
        </Grid>
    );
};
