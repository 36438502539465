import { MenuItem, Popover } from '@mui/material';

export const OrganizationPopover = (props) => {
    const { anchorEl, onClose, open, scmOrgs, onChange, ...other } = props;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 248 } }}
            transitionDuration={0}
            {...other}
        >
            {scmOrgs.length > 1 && (
                <MenuItem key="all" onClick={() => onChange('')}>
                    All organizations
                </MenuItem>
            )}
            {scmOrgs.map((scmOrg) => (
                <MenuItem key={scmOrg.id} onClick={() => onChange(scmOrg.id)}>
                    {scmOrg.name}
                </MenuItem>
            ))}
        </Popover>
    );
};
