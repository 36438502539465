// material
import {
    // Card,
    Stack,
    Container,
    Typography,
    // FormControl,
    // InputLabel,
    // Select,
    // OutlinedInput,
    // MenuItem,
    // Checkbox,
    // ListItemText,
} from '@mui/material';
// components
import { useEffect, useMemo } from 'react';
// import TimezoneSelect from '../../components/timezone_select';
// import { gql, useMutation, useQuery } from '@apollo/client';
// import { allTimezones } from '../../utils/timezones';
import { useAuth } from '../../hooks/use-auth.js';
import Page from '../../components/Page.jsx';
import { SlackConnections } from './SlackConnections';
import { gql, useMutation } from '@apollo/client';

// const USER_SETTINGS_QUERY = gql`
//     {
//         userSettings {
//             notificationDays
//             notificationTimeStart
//             notificationTimeEnd
//             timezone
//             mergeableNotifications
//             requiredTestsCompletedNotifications
//         }
//     }
// `;
// const SET_TIMEZONE = gql`
//     mutation SetTimezone($data: UpdateSettingsInput!) {
//         updateSettings(data: $data) {
//             id
//         }
//     }
// `;

const daysOfWeek = {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
};

const DISCONNECT_SLACK_CONNECTION = gql`
    mutation DisconnectSlack($scmUserOrgId: String!) {
        disconnectSlackConnection(scmUserOrgId: $scmUserOrgId)
    }
`;

export function Settings() {
    const [disconnectSlack, { data: disconnectSlackData }] = useMutation(DISCONNECT_SLACK_CONNECTION);
    // const [setSettings, { data: savedSettings }] = useMutation(SET_TIMEZONE);
    // const [tempSettings, setTempSettings] = useState(null);
    const { user, refetch } = useAuth();

    const handleDisconnectSlack = (scmUserOrgId) => {
        disconnectSlack({
            variables: {
                scmUserOrgId,
            },
        });
    };

    useEffect(() => {
        if (disconnectSlackData) {
            refetch();
        }
    }, [disconnectSlackData, refetch]);

    // const handleSettings = (field, e, value) => {
    //     const updatedSettings = {
    //         ...tempSettings,
    //         [field]: value.id,
    //     };
    //     setTempSettings(updatedSettings);
    //     // setSettings({
    //     //     variables: {
    //     //         data: updatedSettings,
    //     //     },
    //     // });
    // };

    // const handleDaysChange = (e) => {
    //     const {
    //         target: { value },
    //     } = e;
    //     const updatedSettings = {
    //         ...tempSettings,
    //         notificationDays: value,
    //     };
    //     setTempSettings(updatedSettings);
    //     setSettings({
    //         variables: {
    //             data: updatedSettings,
    //         },
    //     });
    // };

    // useEffect(() => {
    //     if (data) {
    //         const tempSettings = { ...data.userSettings };
    //         delete tempSettings.__typename;
    //         setTempSettings(tempSettings);
    //     }
    // }, [data, setTempSettings]);

    const slackConnections = useMemo(() => {
        if (!user) {
            return [];
        }
        return user.userOrgs.map((userOrg) => {
            return {
                id: userOrg.id,
                slackTeam: userOrg.slackUserInfoId ? userOrg.scmOrg.slackTeamInfo.name : null,
                orgName: userOrg.scmOrg.name,
            };
        });
    }, [user]);

    return (
        <Page title="Settings">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Settings
                    </Typography>
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Slack Connections
                </Typography>
                <Stack>
                    <SlackConnections slackConnections={slackConnections} onDisconnect={handleDisconnectSlack} />
                </Stack>
                {/*{tempSettings && (*/}
                {/*    <Card style={{ padding: '40px 20px', overflow: 'visible' }}>*/}
                {/*        <FormControl sx={{ minWidth: 400 }}>*/}
                {/*            <InputLabel id="demo-multiple-checkbox-label">Days of week for notifications</InputLabel>*/}
                {/*            <Select*/}
                {/*                labelId="demo-multiple-checkbox-label"*/}
                {/*                id="demo-multiple-checkbox"*/}
                {/*                multiple*/}
                {/*                value={tempSettings.notificationDays || []}*/}
                {/*                onChange={handleDaysChange}*/}
                {/*                input={<OutlinedInput label="Day" />}*/}
                {/*                renderValue={(selected) => selected.map((x) => daysOfWeek[x]).join(', ')}*/}
                {/*            >*/}
                {/*                {Object.keys(daysOfWeek).map((dayNum) => (*/}
                {/*                    <MenuItem key={dayNum} value={dayNum}>*/}
                {/*                        <Checkbox*/}
                {/*                            checked={*/}
                {/*                                tempSettings.notificationDays &&*/}
                {/*                                tempSettings.notificationDays.includes(dayNum)*/}
                {/*                            }*/}
                {/*                        />*/}
                {/*                        <ListItemText primary={daysOfWeek[dayNum]} />*/}
                {/*                    </MenuItem>*/}
                {/*                ))}*/}
                {/*            </Select>*/}
                {/*        </FormControl>*/}
                {/*        <FormControl>*/}
                {/*            <TimezoneSelect*/}
                {/*                value={allTimezones[tempSettings.timezone]}*/}
                {/*                onChange={handleSettings.bind(null, 'timezone')}*/}
                {/*            />*/}
                {/*        </FormControl>*/}
                {/*    </Card>*/}
                {/*)}*/}
            </Container>
        </Page>
    );
}
