export const STATS_OPTIONS = [
    {
        value: 'activePullRequestCount',
        label: 'Active PRs',
        valueName: '# of PRs',
        countName: '# of PRs',
        excludeUserStat: true,
    },
    {
        value: 'activeReviewCount',
        label: 'Active reviews',
        valueName: 'PR Reviews',
        countName: '# of PRs',
        excludeUserStat: true,
    },
    {
        value: 'pullRequestCount',
        label: 'PRs merged',
        valueName: '# of PRs',
        countName: '# of PRs',
    },
    {
        value: 'pullRequestTime',
        label: 'PR duration',
        valueName: 'Median Hours',
        countName: '# of PRs',
    },
    {
        value: 'reviewCount',
        label: 'PRs reviewed',
        valueName: 'PR Reviews',
        countName: '# of PRs',
    },
    {
        value: 'pullRequestLinesChanged',
        label: 'Lines changed per PR',
        valueName: 'Median Lines Changed',
        countName: '# of PRs',
    },
    {
        value: 'pullRequestFilesChanged',
        label: 'Files changed per PR',
        valueName: 'Median Files Changed',
        countName: '# of PRs',
    },
    {
        value: 'totalLinesChanged',
        label: 'Total lines changed all PRs',
        valueName: 'Total Lines Changed',
        countName: '# of PRs',
    },
    {
        value: 'totalFilesChanged',
        label: 'Total files changed all PRs',
        valueName: 'Total Files Changed',
        countName: '# of PRs',
    },
    {
        value: 'reviewerApproval',
        label: 'As reviewer, review approval',
        valueName: 'Approval Percentage',
        countName: '# of Reviews',
    },
    {
        value: 'authorApproval',
        label: 'As author, review approval',
        valueName: 'Approval Percentage',
        countName: '# of Reviews',
    },
    {
        value: 'reviewerTotalTime',
        label: 'As reviewer, review time per PR',
        valueName: 'Median Hours',
        countName: '# of PRs',
    },
    {
        value: 'authorTotalTime',
        label: 'As author, review time per PR',
        valueName: 'Median Hours',
        countName: '# of PRs',
    },
    {
        value: 'reviewRoundTime',
        label: 'As reviewer, review time per review round',
        valueName: 'Median Hours',
        countName: '# of Reviews',
    },
    {
        value: 'reviewerAvgRounds',
        label: 'As reviewer, review rounds per PR',
        valueName: 'Avg. Rounds',
        countName: '# of PRs',
    },
    {
        value: 'authorAvgRounds',
        label: 'As author, review rounds per reviewer',
        valueName: 'Avg. Rounds',
        countName: '# of PRs',
    },
    {
        value: 'reviewerAvgComments',
        label: 'As reviewer, comments per PR',
        valueName: 'Avg. Comments',
        countName: '# of PRs',
    },
    {
        value: 'authorAvgComments',
        label: 'As author, comments per PR',
        valueName: 'Avg. Comments',
        countName: '# of PRs',
    },
];

export const STATS_DAYS = [
    {
        value: 'ThirtyDays',
        label: '30 Days',
    },
    {
        value: 'NinetyDays',
        label: '90 Days',
    },
    {
        value: 'OneEightDays',
        label: '180 Days',
    },
    {
        value: 'OneYear',
        label: '1 Year',
    },
];
