import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { DashboardLayout } from './layout/dashboard-layout';

import NeedsReview from './pages/NeedsReview.jsx';
import CreatedReviews from './pages/CreatedReviews';
import Settings from './pages/Settings';
import Stats from './pages/Stats';
import ChartStats from './pages/Stats/ChartStats';
import TableStats from './pages/Stats/TableStats';
import NotFound from './pages/Page404';
import SlackConnect from './pages/SlackConnect.jsx';
import NotInstalled from './pages/NotInstalled';
import Login from './pages/Login';
import CommitStatuses from './pages/CommitStatuses.jsx';
import SlackConfirm from './pages/SlackConfirm.jsx';
import SlackFailed from './pages/SlackFailed.jsx';
import MyStats from './pages/MyStats';
import { PreserveQueryNavigate } from './components/PreserveQueryNavigate';

// ----------------------------------------------------------------------
export default function Router() {
    return useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { path: 'needs-review', element: <NeedsReview /> },
                { path: 'created-reviews', element: <CreatedReviews /> },
                {
                    path: 'stats/',
                    element: <Stats />,
                    children: [
                        { path: 'comparison', element: <ChartStats /> },
                        { path: 'all-users', element: <TableStats /> },
                        { path: '', element: <Navigate to="/dashboard/stats/comparison" /> },
                    ],
                },
                {
                    path: 'stats/my',
                    element: <MyStats />,
                },
                { path: 'settings', element: <Settings /> },
                { path: 'checks', element: <CommitStatuses /> },

                // Add a fallback for the old paths
                { path: 'review-requests', element: <PreserveQueryNavigate to="/dashboard/needs-review" /> },
                { path: 'prs-created', element: <PreserveQueryNavigate to="/dashboard/created-reviews" /> },
                { path: '', element: <Navigate to="/dashboard/created-reviews" /> },
            ],
        },
        { path: '/not-installed', element: <NotInstalled /> },
        { path: '/slack/connect', element: <SlackConnect /> },
        { path: '/slack/confirm', element: <SlackConfirm /> },
        { path: '/slack/failed', element: <SlackFailed /> },
        { path: '/', element: <Navigate to="/dashboard/prs-created" /> },
        { path: '404', element: <NotFound /> },
        { path: '/login', element: <Login /> },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
