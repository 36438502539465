import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Link } from '@mui/material';
import Page from '../components/Page';
// components

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SlackConnect() {
    return (
        <Page title="Connect to Slack">
            <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', py: 4 }}>
                    <Typography variant="h3" paragraph>
                        Enable notifications by connecting to Slack
                    </Typography>
                    <Box
                        component="img"
                        src="/static/logos/slack_mark.svg"
                        sx={{ height: 260, mx: 'auto', my: { xs: 1, sm: 1 } }}
                    />
                    <Box sx={{ justifyContent: 'center' }}>
                        <Button
                            href="/api/slack/oauth"
                            sx={{ height: 80, width: 320, fontSize: '1.2rem' }}
                            variant="contained"
                            component={Link}
                        >
                            Connect to Slack
                        </Button>
                        <Box sx={{ mt: 2 }}>
                            <Button to="/" size="large" variant="text" color="success" component={RouterLink}>
                                Skip for now
                            </Button>
                        </Box>
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    );
}
