import { useEffect, useState } from 'react';
import { STATS_DAYS, STATS_OPTIONS } from './constants/stats_options';
import { gql, useQuery } from '@apollo/client';
import { Card, FormControl, MenuItem, TextField } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CircularProgress as Loading } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const PRS_QUERY = gql`
    query GetStats($name: StatName!, $duration: StatDuration!) {
        comparisonStats(name: $name, duration: $duration) {
            username
            count
            value
        }
    }
`;

export default function TableStats() {
    const [search, setSearch] = useSearchParams();
    const defaultStat = STATS_OPTIONS.find((statOption) => statOption.value === search.get('stat')) || STATS_OPTIONS[0];
    const defaultDays = STATS_DAYS.find((dayOption) => dayOption.value === search.get('duration')) || STATS_DAYS[0];
    const [selectedStat, setSelectedStat] = useState(defaultStat);
    const [duration, setDuration] = useState(defaultDays);
    const { loading, data, refetch } = useQuery(PRS_QUERY, {
        variables: {
            name: selectedStat.value,
            duration: duration.value,
        },
    });
    const rows = data ? data.comparisonStats : [];

    useEffect(() => {
        setSearch({
            stat: selectedStat.value,
            duration: duration.value,
        });
    }, [duration, selectedStat]);

    const table = () => {
        const columns = [
            { field: 'username', headerName: 'Username', width: 200 },
            { field: 'value', type: 'number', headerName: selectedStat.valueName, flex: 1 },
            {
                field: 'count',
                headerName: selectedStat.countName,
                type: 'number',
                flex: 1,
            },
        ];

        return (
            <DataGrid
                autoHeight
                getRowId={(row) => row.username}
                components={{ Toolbar: GridToolbar }}
                csvOptions={{
                    fileName: 'github-data-export',
                    delimiter: ';',
                    utf8WithBom: true,
                }}
                rows={rows}
                columns={columns}
            />
        );
    };

    const handleStatChange = ({ target }) => {
        const newStat = STATS_OPTIONS.find((stat) => stat.value === target.value);

        setSelectedStat(newStat);
        refetch({ name: newStat.value, duration: duration.value });
    };

    const handleDurationChange = ({ target }) => {
        const newDuration = STATS_DAYS.find((dayOption) => dayOption.value === target.value);

        setDuration(newDuration);
        refetch({ name: selectedStat.value, duration: newDuration.value });
    };

    return (
        <>
            <FormControl
                sx={{
                    mb: 2,
                    mr: 2,
                }}
            >
                <TextField value={selectedStat.value} label="Stat" select onChange={handleStatChange}>
                    {STATS_OPTIONS.map((report) => (
                        <MenuItem key={report.value} value={report.value}>
                            {report.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <FormControl
                sx={{
                    mb: 2,
                }}
            >
                <TextField value={duration.value} label="Time Range" select onChange={handleDurationChange}>
                    {STATS_DAYS.map((report) => (
                        <MenuItem key={report.value} value={report.value}>
                            {report.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <Card>{loading ? <Loading /> : table()}</Card>
        </>
    );
}
